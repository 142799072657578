@import "../theme/mixins";

.cdk-drag-placeholder {
    opacity: 0;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
    @include table-cell();

    td {
        width: calc(100vw - var(--mat-sidenav-width));
        max-width: 1200px;
    }
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
    .cdk-drag {
        &:not(.cdk-drag-placeholder) {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
    }
}
