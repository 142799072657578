nav {
    .mat-mdc-tab-link-container {
        background-color: var(--broken-white);
        margin: calc(var(--space) * 2) calc(var(--space) * 4);
        border-radius: var(--card-border-radius);

        .mat-mdc-tab-list {
            .mat-mdc-tab-links {
                .mat-mdc-tab-link {
                    .mdc-tab__content {
                        .mdc-tab__text-label {
                            font: var(--mat-menu-item-font);
                            color: black;
                        }
                    }

                    &.mdc-tab--active.mdc-tab-indicator--active {
                        .mdc-tab__content {
                            .mdc-tab__text-label {
                                color: var(--primary-button);
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 599px) {
        .mat-mdc-tab-link-container {
            margin: 0;
            border-radius: 0;
        }

        .mat-mdc-tab-header-pagination {
            background-color: var(--broken-white);
        }
    }
}
