.cdk-overlay-container {
    .cdk-global-overlay-wrapper {
        .cdk-overlay-pane {
            .mat-mdc-snack-bar-container {
                --mdc-snackbar-supporting-text-size: 16px;
                --mdc-snackbar-supporting-text-font: Inter;
                --mdc-snackbar-supporting-text-weight: 500;
                --mdc-snackbar-supporting-text-line-height: 1;

                .mdc-snackbar__surface {
                    text-align: center;
                }

                &.--is-warn {
                    --mdc-snackbar-container-color: var(--danger-color);
                }
            }
        }
    }
}
