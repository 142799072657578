// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$omm-palette: (
    50: #e4f6eb,
    100: #bce8cd,
    200: #90d8ac,
    300: #63c88a,
    400: #41bd71,
    500: #20b158,
    600: #1caa50,
    700: #18a147,
    800: #13983d,
    900: #0b882d,
    A100: #b8ffc7,
    A200: #85ffa0,
    A400: #52ff78,
    A700: #38ff64,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

@import "./theme/reset.scss";
@import "./theme/variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700&family=Poppins:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

$admin-primary: mat.define-palette($omm-palette);
$admin-accent: mat.define-palette(mat.$grey-palette, A200, A100, A400);
$admin-warn: mat.define-palette(mat.$red-palette);

$admin-typography: mat.define-typography-config(
    $font-family: "Inter",
);

$admin-theme: mat.define-light-theme(
    (
        color: (
            primary: $admin-primary,
            accent: $admin-accent,
            warn: $admin-warn,
        ),
        typography: $admin-typography,
    )
);

@include mat.all-component-themes($admin-theme);

@import "./theme/mat-button.scss";
@import "./theme/mat-form-field.scss";
@import "./theme/mat-dialog.scss";
@import "./theme/mat-sidenav.scss";
@import "./theme/mat-menu.scss";
@import "./theme/mat-tabs.scss";
@import "./theme/mat-checkbox.scss";
@import "./theme/mat-snackbar.scss";
@import "./theme/mat-table.scss";
@import "./theme/mat-chips.scss";
@import "./theme/cdk-drag.scss";
@import "./theme/mat-list.scss";

@media screen and (max-width: 599px) {
    .page {
        margin-top: var(--mat-toolbar-height);
        padding-bottom: calc(var(--mat-toolbar-height) * 2);
    }
}
