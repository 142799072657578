.cdk-overlay-container {
    .cdk-global-overlay-wrapper {
        .cdk-overlay-pane {
            mat-dialog-container {
                .mdc-dialog__container {
                    .mdc-dialog__surface {
                        .ng-star-inserted {
                            h1 {
                                font: var(--heading-1);
                                padding: calc(var(--space) * 2) calc(var(--space) * 4);
                            }

                            h2 {
                                font: var(--heading-2);
                                padding: calc(var(--space) * 2) calc(var(--space) * 4);
                            }

                            .mdc-dialog__content {
                                padding: 0 calc(var(--space) * 4);

                                h2 {
                                    font: var(--heading-2);
                                    margin-bottom: calc(var(--space) * 2);
                                    color: var(--black);
                                }

                                h3 {
                                    font: var(--heading-3);
                                    margin-bottom: var(--space);
                                    color: var(--black);
                                }

                                p {
                                    font: var(--paragraph-2);
                                    color: var(--black);
                                }
                            }

                            .mdc-dialog__actions {
                                padding: calc(var(--space) * 3) calc(var(--space) * 4);

                                button {
                                    &.mat-mdc-outlined-button {
                                        --mdc-outlined-button-outline-color: var(--primary-button);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
