@import "./mixins.scss";

button {
    &.mat-mdc-raised-button,
    &.mat-mdc-outlined-button,
    &.mat-mdc-unelevated-button,
    &.mat-mdc-button,
    &.mat-mdc-menu-item {
        &.--is-disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &.--truncate-text {
            span.mdc-button__label,
            span.mdc-list-item__primary-text {
                text-align: left;
                @include truncate-text(90%);
            }
        }

        &.--center-text {
            span.mdc-button__label,
            span.mdc-list-item__primary-text {
                text-align: center;
            }
        }
    }

    &.mat-mdc-raised-button {
        &.mat-primary {
            span.mdc-button__label {
                color: white;
                font-weight: 500;
            }
        }
    }

    &.mat-mdc-button {
        &.mat-primary {
            span.mdc-button__label {
                font-weight: 500;
                letter-spacing: normal;
            }
        }
    }
}
