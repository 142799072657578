@mixin card-section() {
    border: 1px solid var(--border-color);
    border-radius: var(--card-border-radius);
    padding: calc(var(--space) * 3) calc(var(--space) * 3) calc(var(--space) * 2) calc(var(--space) * 3);
    position: relative;
}

@mixin truncate-text($width) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: $width;
}

@mixin table-cell() {
    td {
        font: var(--paragraph-2);
        padding: calc(var(--space) * 2);

        mat-chip {
            --mdc-chip-container-shape-radius: var(--card-border-radius);
        }

        button {
            &.mat-mdc-mini-fab {
                box-shadow: none;
                margin-right: calc(var(--space) / 2);
                background-color: transparent;
                --mat-icon-color: var(--mat-icon-color-soft);

                &[color="warn"] {
                    --mat-icon-color: var(--danger-color);

                    &:hover {
                        background-color: var(--danger-color-soft);
                    }
                }

                mat-icon {
                    --size: 20px;
                    width: var(--size);
                    height: var(--size);
                }
            }
        }

        &.--is-reorder {
            width: 80px;
        }

        &.--is-actions {
            --button-size: 48px;
            text-align: right;

            &.--has-2-actions {
                min-width: calc(calc(var(--button-size) * 2) + calc(var(--space) * 4));
            }
        }

        &.--is-clickable {
            cursor: pointer;
        }

        &.--is-thumbnail {
            $size: 45px;
            width: $size;
            height: $size;
            padding: 16px 0;

            div {
                width: $size;
                height: $size;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 4px;
            }
        }

        div.--is-more-prices {
            font: var(--placeholder);
            color: var(--mat-icon-color-soft);
            font-size: 12px;
        }
    }
}

@mixin image-input() {
    --size: 150px;
    cursor: pointer;
    position: relative;
    height: var(--size);
    width: var(--size);
    border-radius: var(--card-border-radius);
    border: 1px dashed;
    background-color: var(--broken-white);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font: var(--paragraph-2);
    font-style: italic;
    font-size: 12px;
    display: flex;
    text-align: center;

    &.--has-background-image {
        border: none;
    }

    p {
        margin: auto;
    }

    &__remove {
        --mat-icon-color: white;
        --size: 20px;
        position: absolute;
        top: calc(var(--size) * -1);
        right: calc(var(--size) * -1);
    }

    &.--is-hero {
        height: calc(var(--size) * 2);
        width: 100%;
        max-width: 85vw;
    }
}

@mixin material-icon($content) {
    $size: 10px;
    font-family: "Material Icons", sans-serif;
    content: $content;
    display: inline-block;
    position: absolute;
    font-size: $size;
    color: currentColor;
    opacity: 0.8;
}
