/* stylelint-disable no-descending-specificity */
@import "../theme/mixins.scss";

.mat-toolbar {
    position: fixed;
    z-index: 2;

    --mat-icon-color: white;

    button {
        margin-right: calc(var(--space) * 2);
    }

    mat-icon {
        margin-right: var(--space);
    }

    .title {
        font: var(--heading-3);
    }

    .spacer {
        flex: 1 1 auto;
    }
}

mat-sidenav-container {
    flex: 1;
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));

    mat-sidenav {
        width: var(--mat-sidenav-width);
        transition: width 0.3s;

        .mat-drawer-inner-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: var(--broken-white);

            .sidenav__button {
                --size: 24px;
                width: var(--size);
                height: var(--size);

                &.mat-mdc-mini-fab {
                    position: fixed;
                    z-index: 3;
                    top: calc(var(--size) / 2);
                    left: calc(var(--mat-sidenav-width) - calc(var(--size) / 2));

                    mat-icon {
                        width: 16px;
                        height: 16px;
                    }

                    &.--is-folded {
                        mat-icon {
                            transform: rotate(180deg);
                        }
                    }
                }

                &:hover {
                    --mdc-fab-container-color: var(--primary-button);
                    --mat-icon-color: white;
                }
            }

            mat-nav-list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: calc(var(--space) * 2) 0 calc(var(--space) * 2);

                span {
                    &.--is-trial-label {
                        margin: auto;
                        margin-bottom: -8px;
                        font: var(--paragraph-2);
                    }
                }

                button {
                    display: flex;
                    height: 48px;
                    justify-content: flex-start;
                    margin: var(--space) calc(var(--space) * 2);
                    width: calc(var(--mat-sidenav-width) - (var(--space) * 4));

                    &.--active {
                        background-color: var(--sidenav-button-active-color);
                    }

                    &.--has-right-icon {
                        flex-direction: row-reverse;
                        justify-content: space-between;
                    }

                    &.--is-trial {
                        font: var(--placeholder);
                        font-size: 12px;

                        &.mat-mdc-mini-fab {
                            box-shadow: none;
                            .mdc-button__label {
                                margin: auto;
                            }
                        }
                    }

                    span.mdc-button__label {
                        font: var(--sidemenu);
                    }

                    mat-icon.mat-icon {
                        --size: 24px;
                        width: var(--size);
                        height: var(--size);

                        &[data-mat-icon-name="caret-down"] {
                            --size: 16px;
                            margin-bottom: 4px;
                        }
                    }
                }

                .logo {
                    display: none;
                    --size: 48px;
                    width: var(--size);
                    margin: var(--size) auto calc(var(--size) / 4);

                    &-lettering {
                        --size: 24px;
                        width: calc(var(--mat-sidenav-width) - (var(--space) * 4));
                        margin: var(--size) auto;
                    }
                }

                mat-divider {
                    margin: calc(var(--space) * 2) auto;
                    width: calc(var(--mat-sidenav-width) - (var(--space) * 4));
                }

                .qr-code {
                    --size: 140px;
                    height: var(--size);
                    display: flex;
                    margin-left: 12px;

                    canvas {
                        width: var(--size);
                        height: var(--size);
                    }

                    &__buttons {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;

                        button {
                            --size: 40px;
                            width: var(--size);
                            height: var(--size);
                            background-color: var(--sidenav-button-active-color);
                            box-shadow: none;

                            mat-icon {
                                --size: 20px;
                                margin: auto;
                            }
                        }
                    }

                    &.--is-hidden {
                        display: none;
                    }

                    &.--is-folded {
                        canvas {
                            display: none;
                        }

                        .qr-code__buttons {
                            button {
                                margin-left: 10px;
                                background-color: var(--broken-white);
                                border: 1px solid var(--mat-icon-color);
                            }
                        }
                    }
                }

                &:last-child {
                    display: flex;
                    flex-direction: column;

                    button {
                        &:last-child {
                            --mat-icon-color: var(--danger-color);
                        }
                    }
                }
            }
        }

        &.--is-folded {
            width: var(--mat-sidenav-width-folded);
            transition: width 0.3s;

            .mat-drawer-inner-container {
                mat-nav-list {
                    .logo {
                        display: block;
                        margin: 24px auto;

                        &-lettering {
                            display: none;
                        }
                    }

                    button {
                        height: 36px;
                        width: 36px;
                        margin: var(--space) auto var(--space) 21.5px;
                        padding: 6px;
                    }
                }
            }
        }
    }

    mat-sidenav-content {
        max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
        background-color: white;
    }
}

@media screen and (max-width: 599px) {
    mat-sidenav-container {
        max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));

        mat-sidenav {
            .mat-drawer-inner-container {
                mat-nav-list {
                    .logo {
                        display: block;

                        &-lettering {
                            display: none;
                        }
                    }
                }
            }
        }

        mat-sidenav-content {
            max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
        }
    }
}
