@import "../theme/mixins";

.mat-mdc-table {
    thead {
        tr {
            th {
                font: var(--table-heading);
                padding: calc(var(--space) * 2);

                &.--is-button {
                    text-align: right;
                }
            }
        }
    }

    tbody {
        tr {
            @include table-cell();
        }
    }
}
