:root {
    --space: 8px;
    --mat-toolbar-height: 0;
    --mat-nav-height: 48px;
    --mat-sidenav-width: 230px;
    --mat-sidenav-width-folded: 80px;

    // colors
    --broken-white: whitesmoke;
    --black: #252525;
    --border-color: #e4e4e4;
    --primary-button: #20b158;
    --danger-color: #f65950;
    --danger-color-soft: #f6595010;
    --input-background: #f5f5f5;
    --input-background-hover: #eeeeee;
    --mat-icon-color: #252525;
    --mat-icon-color-soft: #616161;
    --sidenav-button-active-color: #d8d8d8;

    // typography
    --heading-1: 600 2rem/1 Poppins;
    --heading-2: 600 1.5rem/1 Poppins;
    --heading-3: 600 1.2rem/1 Poppins;
    --table-heading: 600 1rem/1 Poppins;
    --paragraph-1: 300 18px/1.5 Inter;
    --paragraph-1-bold: 700 18px/1.5 Inter;
    --paragraph-2: 300 14px/1.5 Inter;
    --paragraph-2-bold: 700 14px/1.5 Inter;
    --placeholder: 300 14px/1.5 Inter;
    --input-value: 400 16px/1 Inter;
    --sidemenu: 500 14px/1.5 Inter;
    --mat-menu-item-font: 500 14px/1.5 Inter;

    // cards
    --card-border-radius: 8px;
    --input-border-radius: 8px;
    --card-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    @media screen and (max-width: 599px) {
        --mat-toolbar-height: 56px;
    }
}
