.mdc-evolution-chip-set {
    &__chips {
        .menu__content__categories__chipset & {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;

            .mdc-evolution-chip {
                .mdc-evolution-chip__cell {
                    .mdc-evolution-chip__action {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
