mat-form-field {
    &.mat-form-field-appearance-fill {
        .mat-mdc-text-field-wrapper {
            background-color: var(--input-background);
            border-radius: var(--input-border-radius);

            .mat-mdc-form-field-focus-overlay {
                background-color: transparent;
            }

            .mat-mdc-form-field-flex {
                .mat-mdc-form-field-infix {
                    label {
                        &.mdc-floating-label--float-above {
                            color: var(--black);
                        }

                        font: var(--placeholder);
                    }

                    input {
                        &[type="number"] {
                            appearance: textfield;
                            -moz-appearance: textfield;

                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                        }

                        &::placeholder {
                            font: var(--placeholder);
                        }
                    }
                }

                .mat-mdc-form-field-icon-suffix {
                    span {
                        &.--is-currency {
                            margin-right: calc(var(--space) * 2);
                            color: var(--mat-icon-color-soft);
                        }
                    }

                    mat-icon {
                        &[data-mat-icon-name="eye"],
                        &[data-mat-icon-name="eye-closed"],
                        &[data-mat-icon-name="trash"],
                        &[data-mat-icon-name="plus-circle"],
                        &[data-mat-icon-name="x"] {
                            cursor: pointer;
                        }

                        &[data-mat-icon-name="trash"] {
                            --mat-icon-color: var(--danger-color);
                        }
                    }
                }
            }

            .mdc-line-ripple {
                display: none;
            }

            &:hover {
                background-color: var(--input-background-hover);
            }
        }

        .mat-mdc-form-field-subscript-wrapper {
            .mat-mdc-form-field-hint-wrapper {
                padding: 0;

                mat-hint {
                    &.--is-right-aligned {
                        width: 100%;
                        text-align: right;
                    }
                }

                .mat-mdc-form-field-hint-spacer {
                    display: none;
                }
            }

            .mat-mdc-form-field-error-wrapper {
                margin-top: -6px;
                margin-left: -6px;
                font-size: 10px;
            }
        }
    }

    &.--select-with-label-as-value {
        &.mat-form-field-appearance-fill {
            .mat-mdc-text-field-wrapper {
                .mat-mdc-form-field-flex {
                    .mat-mdc-form-field-infix {
                        label {
                            font: var(--input-value);
                            color: rgba(0, 0, 0, 0.87);
                        }
                    }
                }
            }
        }
    }

    &.--no-padding-bottom {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
}
