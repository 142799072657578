.cdk-overlay-container {
    .cdk-overlay-connected-position-bounding-box {
        .cdk-overlay-pane {
            .mat-mdc-menu-panel {
                .mat-mdc-menu-content {
                    button.mat-mdc-menu-item {
                        span {
                            font: var(--mat-menu-item-font);
                        }
                    }

                    .create-establishment-form {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: -8px;

                        mat-form-field {
                            margin: var(--space);

                            .mat-mdc-form-field-subscript-wrapper {
                                display: none;
                            }
                        }

                        button {
                            margin: -8px var(--space) auto auto;
                        }
                    }

                    mat-spinner {
                        margin: var(--space) calc(var(--space) * 12);
                    }
                }

                &.--auth-language-selector {
                    .mat-mdc-menu-content {
                        img {
                            --size: 24px;
                            width: min-content;
                            height: var(--size);
                        }
                    }
                }
            }
        }
    }
}
