.mat-mdc-selection-list {
    .mat-mdc-list-option {
        .mat-mdc-list-option-checkbox-before {
            .mdc-checkbox {
                --mdc-checkbox-selected-checkmark-color: white;
                --mdc-checkbox-selected-icon-color: var(--primary-button);
            }
        }
    }
}
